/* eslint-disable */
const ordersData = [
  {
    status: 'Completed',
    date: '03 Mar, 2020',
    type: 'Buy',
    coin: 'Bitcoin',
    value: '1.00952 BTC',
    usd: '$ 9067.62',
  },
  {
    status: 'Completed',
    date: '04 Mar, 2020',
    type: 'Buy',
    coin: 'Litecoin',
    value: '0.00214 LTC',
    usd: '$ 9067.62',
  },
  {
    status: 'Pending',
    date: '05 Mar, 2020',
    type: 'Sell',
    coin: 'Bitcoin',
    value: '1.00952 BTC',
    usd: '$ 9067.62',
  },
  {
    status: 'Completed',
    date: '03 Mar, 2020',
    type: 'Sell',
    coin: 'Ethereum',
    value: '0.00413 ETH',
    usd: '$ 2123.01',
  },
  {
    status: 'Completed',
    date: '08 Mar, 2020',
    type: 'Buy',
    coin: 'Litecoin',
    value: '1.00124 LTC',
    usd: '$ 1802.62',
  },
  {
    status: 'Pending',
    date: '06 Mar, 2020',
    type: 'Sell',
    coin: 'Ethereum',
    value: '0.00413 ETH',
    usd: '$ 2123.01',
  },
  {
    status: 'Failed',
    date: '05 Mar, 2020',
    type: 'Buy',
    coin: 'Bitcoin',
    value: '1.00952 BTC',
    usd: '$ 9067.62',
  },
  {
    status: 'Completed',
    date: '03 Mar, 2020',
    type: 'Buy',
    coin: 'Ethereum',
    value: '0.00413 ETH',
    usd: '$ 2123.01',
  },
  {
    status: 'Pending',
    date: '10 Mar, 2020',
    type: 'Buy',
    coin: 'Bitcoin',
    value: '1.00952 BTC',
    usd: '$ 9067.62',
  },
  {
    status: 'Pending',
    date: '18 Mar, 2020',
    type: 'Buy',
    coin: 'Litecoin',
    value: '0.00224 LTC',
    usd: '$ 1773.01',
  },
  {
    status: 'Completed',
    date: '03 Mar, 2020',
    type: 'Buy',
    coin: 'Bitcoin',
    value: '1.00952 BTC',
    usd: '$ 9423.73',
  },
  {
    status: 'Pending',
    date: '15 Mar, 2020',
    type: 'Sell',
    coin: 'Ethereum',
    value: '0.00413 ETH',
    usd: '$ 2123.01',
  },
  {
    status: 'Completed',
    date: '03 Mar, 2020',
    type: 'Sell',
    coin: 'Bitcoin',
    value: '1.00952 BTC',
    usd: '$ 9067.62',
  },
  {
    status: 'Failed',
    date: '01 Mar, 2020',
    type: 'Sell',
    coin: 'Litecoin',
    value: '1.00217 LTC',
    usd: '$ 9067.62',
  },
];

export {ordersData};
